<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1 class="pc_brand">Missy</h1>
          <p class="text-center">
            Missy is a KC Kennel Club registered dark red Miniature Poodle with a Five Generations Enhanced Pedigree
            Bloodline (Champion Line).
          </p>
        </b-col>
      </b-row>
      <b-row>
        <GalleryComponent :slides="missySlideshowSlides" height="600" />
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import GalleryComponent from "@/components/GalleryComponent.vue";
import {mapState} from "vuex";

export default {
  name: 'MissyView',
  components: {GalleryComponent},
  computed: mapState([
    'missySlideshowSlides',
  ]),
  mounted() {
    this.$store.dispatch('createMissySlideshowSlides');
  }
}
</script>
