<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1 class="pc_brand">Boutique Breeder of F1 Cavapoos and Poodles</h1>
        </b-col>
      </b-row>
      <b-row>
        <GalleryComponent :slides="homeSlideshowSlides" :height="600"/>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import GalleryComponent from "@/components/GalleryComponent.vue";
import {mapState} from "vuex";

export default {
  name: 'HomeView',
  components: {GalleryComponent},
  computed: mapState([
    'homeSlideshowSlides',
  ]),
  mounted() {
    this.$store.dispatch('createHomeSlideshowSlides');
  }
}
</script>
