<template>
  <b-col cols="12" class="text-center">
    <b-nav justified>
      <b-nav-item to="/">Home</b-nav-item>
      <b-nav-item to="/about">About</b-nav-item>
      <b-nav-item-dropdown text="Our Dogs">
        <b-dropdown-item to="/dogs/rosie">About Rosie</b-dropdown-item>
        <b-dropdown-item to="/dogs/missy">About Missy</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item to="/contact">Contact</b-nav-item>
    </b-nav>
  </b-col>
</template>

<script>
export default {
  name: "NavComponent",
  components: {},
}
</script>

<style scoped lang="scss">

</style>