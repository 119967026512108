<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1 class="pc_brand">About Poodle Curls</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Poodle Curls are a home reared, British boutique breeder of F1 Cavapoos and Miniature Poodle. We are
            <b-img :src="buddy_bunny_url" class="embeddedPortaitImage floatright" thumbnail/>
            experienced and ethical breeders based in Kent who breed purely for the love of the breeds. We do not breed
            to demand and the welfare of our dogs is paramount. Before having children, I was a property lawyer based in
            London but since having kids and spending the majority of my time at home, I discovered a love for rearing
            puppies. I hold a Level 3 Canine First Aid Diploma with a Distinction. I also hold a Dog Grooming Diploma to
            equip me with the skills to groom puppies and have extensive grooming experience. Here at Poodle Curls,
            nails are clipped weekly. Any reputable breeder should be able to nail clip their dogs as it is essential
            for the breeding bitch (mother)to avoid her any discomfort but also developing puppies need to get used to
            being groomed. We only breed from athletic, very healthy and good temperament bitches here at Poodle Curls
            to ensure the offspring are of the the finest possible. Our bitches are deep dark red in colouring and all
            mothers are KC registered with KC Kennel Club possessing Five Generations Enhanced Pedigrees with multiple
            champions in their bloodlines. We like to breed with KC registered Cavalier King Charles Spaniels which have
            been fully health tested and possess excellent temperaments.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2 class="pc_brand">HOME REARED PUPPIES</h2>
          <p>Puppies are raised in our family home around children providing them with an excellent start in their
            lives. I cannot stress enough the importance of puppies being home reared as they need to be regularly held
            and exposed to the usual household noises from the beginning of life. This is of paramount importance for
            puppies to be reared in a family home rather than an isolated outbuilding.
            <b-img :src="garden_play_url" class="embeddedPortaitImage floatleft" thumbnail/>
            We follow a strict socialisation
            plan exposing puppies to everyday sounds, people and children from when they are born. This exposure needs
            to be done when the puppies are young enough to happily accept new things and prevent anxiety issues later
            in life. A puppy that is properly reared in the early weeks will be far more likely to grow up confident,
            calm, more open to learning new things and less likely to respond to new experiences fearfully or
            aggressively. Puppy rearing needs to be done properly, it is hard work and for this reason, I only ever have
            one litter at a time so we can dedicate ourselves to the attention these puppies rightfully deserve. From
            four weeks old, we regularly take the puppies into our garden for toileting and very short play breaks. My
            goal as a breeder is to wholeheartedly provide these puppies with the very best start in life with a love
            for human interaction and a curiosity for the world to explore around them. When my puppies leave me, they
            have been very well trained to use the garden for their toileting so as a future owner, this consistency of
            toilet breaks and outdoor time needs to continue. I’ve overwhelming reviews from all buyers and many have
            recommended me already. I take pride in going above and beyond by giving buyers lots of updates with high
            quality photos and videos weekly at a minimum via Whatsapp. As a buyer, you need to be assured and confident
            that the Breeder is looking after the puppies and regularly updating you as a buyer.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2 class="pc_brand">SELECTION OF OWNERS</h2>
          <p>
            As a breeder, my role is to select the best possible homes for the puppies. I am looking for families who
            will welcome them as a lifelong addition to their family by showing them love and affection from the very
            <b-img :src="truck_ted_url" class="embeddedPortaitImage floatright" thumbnail/>
            start, with lots of walks and importantly, spending a lot of time at home. I feel it is important to have a
            garden as my rearing here depends so much on access to garden space for regular toileting. Many of my
            puppies have gone to homes with cats and the puppies have all got along well with the cats. If you are
            interested in providing a forever home to one of my puppies, please get in touch via the CONTACT US section.
            Please provide as much information as possible about the lifestyle you can provide one of our puppies. For
            example, Do you spend a lot of time working from home? Do you have children the puppy can socialise with? Do
            you have a garden and enjoy walking? It is my responsibility as a breeder to ensure I find the best homes. I
            do not sell to Breeders, puppies are sold to family homes only.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2 class="pc_brand">PUPPIES AT POODLE CURLS</h2>
          <p>
            All puppies are allowed to leave to go to their new homes at 8 weeks of age due to Lucy’s Law which is
            <b-img :src="toilet_training_url" class="embeddedPortaitImage floatleft" thumbnail/>
            designed to protect puppies leaving too early. Puppies will be vet checked, wormed with panacur at 2, 5 and
            just before leaving at 8 weeks old, microchipped and given their first vaccinations together with free
            insurance documentation covering the first few weeks of the puppies’ lives in their new homes.
            Once a deposit to reserve a puppy has been taken, that puppy is no longer marketed and all paperwork is
            given to the buyer by email. I make sure Buyers have all KC paperwork for both Mother and Father, all health
            tests and proof of champion line for their piece of mind. I do not accept any money in cash as fully
            accountable to HMRC and operating 24 HOUR CCTV for the protection of the puppies.
          </p>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'AboutView',
  computed: {
    buddy_bunny_url() {
      return require('@/assets/images/homeGallery/buddy_bunny.jpg')
    },
    garden_play_url() {
      return require('@/assets/images/homeGallery/garden_play.jpg')
    },
    toilet_training_url() {
      return require('@/assets/images/homeGallery/toilet_training.jpg')
    },
    truck_ted_url() {
      return require('@/assets/images/homeGallery/truck_ted.png')
    }
  }
}
</script>
