<template>
  <b-col>
    <vueper-slides v-if="this.height" autoplay :fixedHeight="this.height + 'px'">
      <vueper-slide
          v-for="(slide, i) in this.slides"
          :key="i"
          :image="slide.image"
      />
    </vueper-slides>
    <vueper-slides v-else autoplay>
      <vueper-slide
          v-for="(slide, i) in this.slides"
          :key="i"
          :image="slide.image"
      />
    </vueper-slides>
  </b-col>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: "GalleryComponent",
  props: {
    slides: Array,
    height: Number
  },
  components: {
    VueperSlides,
    VueperSlide
  },
}
</script>

<style scoped lang="scss">

</style>