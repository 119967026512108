import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
// import axiosRetry from "axios-retry";

Vue.use(Vuex)


const state = {
    homeSlideshowSlides: [],
    rosieSlideshowSlides: [],
    missySlideshowSlides: [],
};

const getters = {};

const mutations = {
    setHomeSlideshowSlides(state, payload) {
        state.homeSlideshowSlides = payload;
    },
    setRosieSlideshowSlides(state, payload) {
        state.rosieSlideshowSlides = payload;
    },
    setMissySlideshowSlides(state, payload) {
        state.missySlideshowSlides = payload;
    },
};

const actions = {
    createHomeSlideshowSlides({commit}){
        const path = require.context('@/assets/images/homeGallery/', false, /\.(:?png|jpg|jpeg)$/)
        let images = path.keys().map(path)
        let slides = []
        images.forEach(image => (
           slides.push({
               image: image
           })
        ));
        commit('setHomeSlideshowSlides', slides)
    },
    createRosieSlideshowSlides({commit}){
        const path = require.context('@/assets/images/rosie/', false, /\.(:?png|jpg|jpeg)$/)
        let images = path.keys().map(path)
        let slides = []
        images.forEach(image => (
            slides.push({
                image: image
            })
        ));
        commit('setRosieSlideshowSlides', slides)
    },
    createMissySlideshowSlides({commit}){
        const path = require.context('@/assets/images/missy/', false, /\.(:?png|jpg|jpeg)$/)
        let images = path.keys().map(path)
        let slides = []
        images.forEach(image => (
            slides.push({
                image: image
            })
        ));
        commit('setMissySlideshowSlides', slides)
    },

};



const modules = {};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules
})
