<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1 class="pc_brand">Contact Us</h1>
          <p class="text-center">
            To find out more about current or upcoming litters, follow us on social media using the icons below.
          </p>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-col class="text-center">
          <h2>Instagram</h2>
          <a href="https://www.instagram.com/poodlecurls_uk" target="_blank"><b-img thumbnail class="socialLogo" :src="insta_logo_url" /></a>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ContactView',
  computed: {
    insta_logo_url() {
      return require('@/assets/images/instagram/instagram_glyph_gradient.png')
    },
  },
}
</script>
