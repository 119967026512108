<template>
	<b-container>
		<b-row>
			<b-col class="my-auto text-center">
				<b-img src='/missy_head_colored128.png' id="logo" alt="Poodlecurls Logo" /> <span class="pc_brand h1">Poodle Curls</span>
			</b-col>
		</b-row>
    <b-row>
      <NavComponent />
    </b-row>
		<hr />
		<b-row class="my-auto">
			<b-col id="content">
				<router-view/>
			</b-col>
		</b-row>
		<hr />
		<b-row>
			<b-col class="w-100 my-auto">
				<p class="text-right">Copyright {{new Date().getFullYear()}} Poodle Curls</p>
			</b-col>
		</b-row>
	</b-container>
</template>

<style lang="scss">

</style>

<script>
import NavComponent from "@/components/NavComponent.vue";

export default {
	name: 'App',
  components: {NavComponent},
	watch: {
		$route: {
			immediate: true,
			handler(to) {
				document.title = to.meta.title;
			}
		},
	},
};
</script>