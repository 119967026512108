import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from "@/views/AboutView.vue";
import RosieView from "@/views/RosieView.vue";
import MissyView from "@/views/MissyView.vue";
import ContactView from "@/views/ContactView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Poodle Curls Home' }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { title: 'About Poodle Curls' }
  },
  {
    path: '/dogs/rosie',
    name: 'rosie',
    component: RosieView,
    meta: { title: 'About Rosie' }
  },
  {
    path: '/dogs/missy',
    name: 'missy',
    component: MissyView,
    meta: { title: 'About Missy' }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: { title: 'Contact Us' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
